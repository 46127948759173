















import Vue from "vue";
import Component from "vue-class-component";
import { Watch } from "vue-property-decorator";

import animateLogo from "./../ts/animateLogo"
import getCookie from "./../ts/getCookie";

@Component
export default class SitePagination extends Vue {
  currentPageNum = 1;
  direction = "";
  recordCount = getCookie("recordCount");
  //nextLink = getCookie("nextLink");
  activateNav = true;

  created(): void {
    this.$store.commit("setMaxItemsPerPage", getCookie("maxItemsPerPage"));
  }

  private pageRecords(dir) {
    this.direction = dir;
    this.activateNav = false;

    animateLogo('logo-image', 'spin')

    if (this.currentPageNum !== this.$store.state.pageNum) {
      this.currentPageNum = this.$store.state.pageNum;
    }

    if (dir == "next") this.currentPageNum++;
    if (dir == "prev") this.currentPageNum--;

    this.$store.commit("setPagingDirection", dir);
    this.$store.commit("setPageNum", this.currentPageNum);

    //this.$store.commit("setNextRecord", getCookie("nextLink"));
    //this.nextLink = this.$store.state.setNextRecord;
    this.recordCount = this.$store.state.pageRecordCount;

    animateLogo('logo-image', '')
  }

  // TODO: fix next link.  If the record count is the max then there will be a next link which is not right
  // Each query needs to check for one more record to determine if there are more records
  private nextLinkValue() {
    return this.$store.state.pageRecordCount == this.$store.state.maxRecords;
  }

  @Watch("currentPageNum")
  async onPropertyChanged(value: string): Promise<void> {
    let queryString = this.$route.path + "?page=" + value;
    if (this.direction) queryString += "&dir=" + this.direction;
    if (this.$store.state.search)
      queryString += "&q=" + this.$store.state.search;

    this.$router.push({ path: queryString }).catch((err) => {
      console.log(err);
    });

    this.activateNav = true;
  }
}
